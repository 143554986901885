import React, { useEffect, useState } from 'react';
import styles from './PostRegScreen2.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import mobileSignal from '../../../img/signal.svg';
import { Button, Modal } from "react-bootstrap";

const PostRegScreen2 = () => {
    const navigate = useNavigate();
    // проверим токен для мобильного бэка
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    // Состояние для управления модальным окном1
    const [showModal, setShowModal] = useState(false);

    // Состояние для управления модальным окном2
    const [showModal2, setShowModal2] = useState(false);

    const handleConnect = () => {
        navigate('/connect');
    }

    const handleSkip = () => {
        navigate('/myStations');
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h1 className={styles.titleText}>Подключите свою<br /> персональную станцию</h1><br />
                <p>Для подключения вам понадобится <br />физический доступ к зарядной станции </p>
            </div>
            <div className={styles.section}></div>
            <div className={styles.section}></div>
            <div className={styles.sectionButton}>
                <div className={styles.skipContainer}>
                <button
                    type="button"
                    onClick={handleSkip}
                    className={styles.submitButtonSkip}
                >
                    <p className={styles.textButtons}>Пропустить</p>
                </button>
                </div>
                <div className={styles.skipContainer}>
                <button
                    type="button"
                    onClick={handleConnect}
                    className={styles.submitButtonConnect}
                >
                    <p className={styles.textButtons}>Подключить</p>
                    <img src={mobileSignal} className={styles.mobileSignal} alt='signal' />
                </button>
                </div>
            </div>

            {/* Модальное окно подключения станции*/}
            {/* <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Подключаем станцию</Modal.Title>
                </Modal.Header>
                <Modal.Body>Станция успешно подключена <br/> <b>Продолжаем?</b> </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Нет
                    </Button>
                    <Button variant="danger" onClick={() => {
                        navigate('/dash')
                    }}>
                        Да
                    </Button>
                </Modal.Footer>
            </Modal> */}

            {/* Модальное окно2 */}
            {/* <Modal show={showModal2} onHide={() => setShowModal2(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Станция не подключена</Modal.Title>
                </Modal.Header>
                <Modal.Body>Необходмо подключить станцию <br/></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal2(false)}>
                        Отмена
                    </Button>
                    <Button variant="danger" onClick={() => setShowModal2(false)}>
                        Ок
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    );
};

export default PostRegScreen2;
