import React, { useState } from "react";
import QrScannerComponent from "./QrScannerComponent";
import styles from './QrTest.module.css';
import qrico from '../../../img/qrico.png';
import config from "../../../settings/config";

const QrTest = () => {
    const [manualInput, setManualInput] = useState(""); 
    const handleScan = (data) => {
        if (data) {
            console.log("QR Code Data:", data);
            if (data.text) {
                // alert(`Считанный QR-код: ${data.text}`);
                console.log(data.text)
                    getRequest(data.text);
                if (data.text.lenght >= 9) {
                    
                }
                
            }
        }
    };

    async function getRequest(data, group) {
        try {
            console.log('Test get req')
            // Формируем query string из параметров
            // const queryString = new URLSearchParams({
            //     sn: JSON.stringify(data), // Преобразуем объект data в строку
            //     groupid: JSON.stringify('1231231231'), // Передаем group как есть
            // }).toString();
    
            // Выполняем запрос с параметрами
            const response = await fetch(`${config.app.host}/api/v1/station/new?sn=${data}&groupId=1231231231`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const responseData = await response.json(); // Переименовал переменную, чтобы избежать конфликта
            console.log(responseData)
    
        } catch (error) {
            // Обработка ошибки с использованием SweetAlert2
        }
    }

    const handleManualSubmit = () => {
        if (manualInput.length >= 9) {
            handleScan({ text: manualInput });
        } else {
            alert("Введите текст длиной не менее 9 символов");
        }
    };

    const handleError = (error) => {
        console.error("QR Scanner Error:", error);
    };

    return (
        <div className={styles.container}>
            <div className={styles.imgContainer}>
                <img src={qrico} alt="ico" className={styles.icon} />
            </div>
            <div className={styles.camContainer}>
                <QrScannerComponent onScan={handleScan} onError={handleError} />
            </div>

            <div className={styles.manualInputContainer}>
                <input
                    type="text"
                    placeholder="Введите данные вручную"
                    value={manualInput}
                    onChange={(e) => setManualInput(e.target.value)}
                    className={styles.manualInput}
                />
                <button onClick={handleManualSubmit} className={styles.submitButton}>
                    Отправить
                </button>
            </div>
        </div>
    );
};

export default QrTest;
