import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeOffer.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "../../../MainRoad/HelpIcon/HelpIcon";
import OtpInput from 'react-otp-input';

const PinCodeOffer = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [otp, setOtp] = useState('');

    function handlePinComplite(test) {
        navigate('/pinoffer2', { state: { firstValues: test } })
    }
    const handleBack = () => {
        navigate('/step3');
    };

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            console.log(otp)
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <p className={styles.titleText}>
                    {!state?.error ? 'Установите пароль,' : ''}
                    <br />
                    {!state?.error ? 'для быстрого доступа' : ''}
                </p>
                <div>{state?.error ? 'Пин - коды не совпадают!' : ''}</div>
                <div>{state?.error ? 'Повторите ввод:' : ''}</div>
            </div>

            <div className={styles.section}>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0} 
                    />}
                    containerStyle={styles.otpInlineInput}
                />
            </div>

            <div className={styles.section}>
                <div className={`${styles.backButton} ${styles.buttons}`} onClick={handleBack}>
                </div>
                <HelpIcon />
            </div>
        </div>
    );
}

export default PinCodeOffer;
