import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './StationControl.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import lightning from "../../../img/ligthning.svg"
import config from '../../../settings/config';
import { toast } from "react-toastify";
import schedule from "../../../img/schedule.svg"
import settings from "../../../img/settings.svg"

const StationControl = () => {
    const location = useLocation();
    const [station, setStation] = useState(location.state?.station);
    // const station = location.state?.station;  // Получаем объект станции из state
    const token = localStorage.getItem('m_token')
    let time = "00:00:00"

    // console.log(station);  // Проверка полученных данных

    const navigate = useNavigate();

    useEffect(() => {
        if (!checkToken(token)) {
            navigate('/step2');
        }
    }, []);

    //localhost:8080/api/v1/station/start?sn=22T333333&maxP=1&maxC=1

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/station?sn=${station.sn}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                // console.log(result)
                setStation(result)
            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, []);

    async function requestStartCharging() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/station/start?sn=${station.sn}&maxP=1&maxC=1`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                },
            });

            const responseData = await response.json();
            // console.log(responseData)

            if (!response.ok) {
                const errorMessage = responseData?.message || 'Неизвестная ошибка';
                toast.error('Ошибка: ' + errorMessage, {
                    position: "top-center",
                    autoClose: 3000
                }
                );
            }
        } catch (error) {
            // toast.error('Ошибка ' + error.text, {
            //     position: "top-center",
            //     autoClose: 3000
            // });
        }
    }

    const handleSchedule = () => {
        
    }

    const handleSettings = () => {
        
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionHeader}></div>
            <div className={styles.sectionTitle}>
                <h1 className={styles.titleText}>{station.nickName}</h1>
                <div 
                    className={styles.settingsContainer}
                    onClick={handleSettings}
                    >
                    <img src={settings} className={styles.imgSettings} alt='settings' />
                    </div>
            </div>
            <div className={styles.mainSection}>
                <div className={styles.timeContainer}>
                    <p className={styles.timeText}>{time}</p>
                </div>
                <div className={styles.card}>
                    <div class={styles.circle}>
                        <div class={styles.innercircle}>
                            <span class={styles.text}>0<br />kWh</span>
                        </div>
                    </div>
                </div>
            </div>
            {/* ))}
                </Slider>
            </div> */}
            <div className={styles.section}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={requestStartCharging}
                        className={styles.submitStartCharging}
                    >
                        <p className={styles.textButtons}>Начать зарядку</p><img src={lightning} className={styles.lightning} alt='lightning' />
                    </button>
                    <button
                        type="button"
                        onClick={handleSchedule}
                        className={styles.submitSchedule}
                    >
                        <p className={styles.textButtons}></p><img src={schedule} className={styles.lightning} alt='schedule' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default StationControl;