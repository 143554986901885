import React, { useEffect, useState } from 'react';
import styles from './ConnectStation.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import wifiLight from "../../../img/wifiLight.png"

const ConnectStation = () => {
    const navigate = useNavigate();
    // проверим токен для мобильного бэка
    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const handleConnect = () => {
        navigate('/connect2');
    }

    const handleSkip = () => {
        navigate('/postreg2');
    }

    return (
        <div className={styles.container}>
            <div className={styles.sectionHeader}></div>
            <div className={styles.section}>
                <h1 className={styles.titleText}>Подключение <br />персональной станции </h1>
                <p>Убедитесь, что Ваша зарядная станция <br /> подключена к Wi-Fi</p>
                <p>На зарядной станции должен гореть зеленый <br /> светодиод, как показано ниже </p>
            </div>
            <div className={styles.section}>
                <div className={styles.mainImage}>
                    <img src={wifiLight} className={styles.wifiLight} alt='wifiLight' />
                </div>
            </div>
            <div className={styles.section}></div>
            <div className={styles.sectionButton}>
                {/* <div className={styles.skipContainer}>
                <button
                    type="button"
                    onClick={handleSkip}
                    className={styles.submitButtonSkip}
                >
                    <p className={styles.textButtons}>Назад</p>
                </button>
                </div> */}
                {/* <div className={styles.skipContainer}>
                <button
                    type="button"
                    onClick={handleConnect}
                    className={styles.submitButtonConnect}
                >
                    <p className={styles.textButtons}>Далее</p>
                </button>
                </div> */}
                <button
                    type="button"
                    onClick={handleConnect}
                    className={styles.submitButtonEnabled}
                >
                    Далее
                    <span className={styles.arrow}>➔</span>
                </button>
            </div>
        </div>
    );

};

export default ConnectStation;
