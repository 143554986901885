import React, {useEffect, useRef} from 'react';
import {Accordion, Button} from 'react-bootstrap';
import styles from './PreProfile.module.css';
import {NavLink, useNavigate} from "react-router-dom";
// Test new AVA
// import defaultAvatar from '../../../img/avatars/hzkto.jpg';
import defaultAvatar from '../../../img/avatars/nAva.png';
import {fetchProfile} from "../profileService";
import {checkToken} from "../../Utils/TokenService";
import config from "../../../settings/config"; // Import the default avatar

const PreProfile = () => {

    const avatarUrl = localStorage.getItem('avatar') || defaultAvatar;
    //const name = localStorage.getItem('name') || '';
    const [data, setData] = React.useState("Надо подписаться!");
    const intervalRef = useRef(null); // Используем useRef для хранения ссылки на интервал
    const handleImageError = (event) => {
        event.target.src = defaultAvatar;
    };

    // Чекаем используемые токены в первую очередь
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');
    useEffect(() => {
        if (!token || !checkToken(token)) {
            navigate('/step2');
        }
    }, [token]);

    const fetchData = async () => {
        try {
            const response = await fetch(`${config.app.host}/api/v1/mqtt/getvalue?topic=testtopic/sta1/temp`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let resp = await response.json();
            setData(resp);
        } catch (error) {
        }
    }



    const [name, setName] = React.useState('');
    React.useEffect(() => {
        const fetch = async () => {
            const profile = await fetchProfile(token);
            if (profile) {
                setName(profile.name);
            }
        };
        fetch();
    }, []);

    async function test() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/mqtt/pub?topic=testtopic/sta1/led&message=0`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let resp = await response.json();
        } catch (error) {
        }
    }

    async function noTest() {
        try {
            const response = await fetch(`${config.app.host}/api/v1/mqtt/pub?topic=testtopic/sta1/led&message=1`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            //let resp = await response.json();
        } catch (error) {
        }
    }

    async function subscribe() {
        setData("Подписан!")
        try {
            const response = await fetch(`${config.app.host}/api/v1/mqtt/sub?topic=testtopic/sta1/temp`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            //let resp = await response.json();
        } catch (error) {
        }
        intervalRef.current = setInterval(fetchData, 3000);
    }

    async function unSubscribe() {
        // Очищаем интервал
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Сбрасываем реф
        }
        try {
            const response = await fetch(`${config.app.host}/api/v1/mqtt/unsub?topic=testtopic/sta1/temp`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            //let resp = await response.json();
            setData("Не подписан!");
        } catch (error) {
        }
    }

    const qrtest = () => {
        navigate('/qrtest');
    }


    return (
        <div className={styles.container}>
            <div
                className={styles.avatarContainer}
                onClick={null}
            >
                <img
                    src={avatarUrl}
                    alt="Аватар пользователя"
                    className={styles.avatar}
                    onError={handleImageError}  // Обработка ошибки загрузки
                />
                <p className={styles.name}>{name}</p>
            </div>
            
            <Accordion className={styles.accordion}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Управление профилем</Accordion.Header>
                    <Accordion.Body>
                        <NavLink to="/profile">Перейти к профилю</NavLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Настройки приложения</Accordion.Header>
                    <Accordion.Body>
                        Настройки приложения в этой версии недоступны.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>Секретное меню для разработчиков</Accordion.Header>
                    <Accordion.Body>
                        <Button variant="dark" type="button" className="w-100 mt-3" onClick={qrtest}>
                            read QR
                        </Button>
                        <Button variant="dark" type="button" className="w-100 mt-3" onClick={test}>
                            Тест
                        </Button>
                        <Button variant="dark" type="button" className="w-100 mt-3" onClick={noTest}>
                            !Тест
                        </Button>
                        <Button variant="dark" type="button" className="w-100 mt-3" onClick={subscribe}>
                            Подписка
                        </Button>
                        <Button variant="dark" type="button" className="w-100 mt-3" onClick={unSubscribe}>
                            Дизлайк, отписка!
                        </Button>
                        <p>Текущие данные: <pre>{data}</pre></p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>


            <div className={`"mt-4" ${styles.contactLinks}`}>
                <p>Свяжитесь с нами:</p>
                <ul>
                    <li>Telegram: <a href="https://t.me/pss_support_bot" target="_blank" rel="noopener noreferrer">Наш
                        бот</a></li>
                    <li>Email: <a href="mailto:help@zs-pss.ru">help@zs-pss.ru</a></li>
                    <li>Телефон: <a href="tel:88005006290#1">8-800-500-62-90 (доб. 1)</a></li>
                </ul>
            </div>
        </div>
    );
};

export default PreProfile;
