import React, { useEffect, useState } from 'react';
import styles from './RegistrationForm.module.css';
import axios from "axios";
import config from "../../../settings/config";
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
    const carData = [
        { brand: 'Tesla', models: ['Model S', 'Model 3', 'Model X', 'Model Y'] },
        { brand: 'Nissan', models: ['Leaf', 'Ariya'] },
        { brand: 'BMW', models: ['i3', 'iX', 'i4'] }
    ];

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        birthday: '',
        carBrand: '',
        carModel: ''
    });
    const navigate = useNavigate();
    const token = localStorage.getItem('m_token');

    useEffect(() => {
        const m_token = localStorage.getItem('m_token');
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);

    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [error, setError] = useState('');
    const [birthdayError, setBirthdayError] = useState(''); // Ошибка для дня рождения

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            validateEmail(value);
        }

        if (name === 'carModel') {
            const selectedBrand = carData.find(car =>
                car.models.includes(value)
            )?.brand || '';

            setFormData({
                ...formData,
                carBrand: selectedBrand,
                carModel: value
            });
        } else if (name === 'birthday') {
            handleBirthdayChange(value);
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Пожалуйста, введите корректный e-mail.');
        } else {
            setError('');
        }
    };

    const handleBirthdayChange = (value) => {
        const sanitizedInput = value.replace(/[^0-9.]/g, ''); // Разрешаем только цифры и точки

        let formattedDate = sanitizedInput
            .replace(/^(\d{2})(\d)/, '$1.$2') // Добавляем первую точку
            .replace(/^(\d{2}\.\d{2})(\d)/, '$1.$2'); // Добавляем вторую точку

        if (formattedDate.length > 10) {
            formattedDate = formattedDate.slice(0, 10); // Ограничиваем длину
        }

        setFormData({ ...formData, birthday: formattedDate });

        // Проверяем, валидна ли дата
        if (formattedDate.length === 10 && !isValidDate(formattedDate)) {
            setBirthdayError('Неверная дата рождения. Проверьте формат.');
        } else {
            setBirthdayError('');
        }
    };

    const isValidDate = (date) => {
        const [day, month, year] = date.split('.').map(Number);
        const dateObj = new Date(year, month - 1, day);
        return (
            dateObj &&
            dateObj.getDate() === day &&
            dateObj.getMonth() === month - 1 &&
            dateObj.getFullYear() === year
        );
    };

    useEffect(() => {
        if (formData.name.trim() !== '' && formData.email.trim() !== '') {
            setIsButtonEnabled(true);
        } else {
            setIsButtonEnabled(false);
        }
    }, [formData]);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formattedData = {
            ...formData,
            birthday: formatDate(formData.birthday) // Форматируем дату для отправки
        };

        axios.post(`${config.app.host}/api/v1/user/profile`, formattedData, axiosConfig)
            .then(response => {
                console.log('Profile updated:', response.data);
                navigate('/postreg', { state: { name: formData.name } });
            })
            .catch(error => {
                console.error('Error updating profile:', error);
            });
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('.');
        return `${year}-${month}-${day}`; // Формат для отправки на сервер
    };

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <h1 className={styles.titleText}>Создание аккаунта</h1>
                <p>Кажется, вы здесь впервые. Введите свои данные, <br/> чтобы получить персонализированный доступ к <br/>зарядным станциям..</p>
                <p className={styles.requiredNote}>*Поля обязательные для заполнения</p>
            </div>
            <div className={styles.section}>
                <form className={styles.form}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name">Как вас зовут?*</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Введите имя"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email">Ваш e-mail*</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Введите свою электронную почту"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {error && <p className={styles.error}>{error}</p>}
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="birthday">Ваш день рождения</label>
                        <input
                            type="text"
                            id="birthday"
                            name="birthday"
                            placeholder="дд.мм.гггг"
                            value={formData.birthday}
                            onChange={handleChange}
                            maxLength={10}
                        />
                        {birthdayError && <p className={styles.error}>{birthdayError}</p>}
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="carModel">Марка и модель электромобиля</label>
                        <select
                            id="carModel"
                            name="carModel"
                            value={formData.carModel}
                            onChange={handleChange}
                        >
                            <option value="">Не выбрано</option>
                            {carData.map((car) => (
                                <optgroup key={car.brand} label={car.brand}>
                                    {car.models.map((model) => (
                                        <option key={model} value={model}>
                                            {model}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </select>
                    </div>
                </form>

                
            </div>
            <div className={styles.section}></div>
            <div className={styles.section}>
                    <div className={styles.submitWrapper}>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className={isButtonEnabled ? styles.submitButtonEnabled : styles.submitButtonDisabled}
                        >
                            Далее
                            <span className={isButtonEnabled ? styles.arrowEnable : styles.arrow}>➔</span>
                        </button>
                    </div>
                </div>
        </div>
    );
};

export default RegistrationForm;
