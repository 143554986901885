import React from "react";
import QrScanner from "react-qr-scanner";

const QrScannerComponent = ({onScan, onError}) => {
    const previewStyle = {
        height: 240,
        width: 320,
    };

    const videoConstraints = {
        facingMode: "environment", // Указывает на заднюю камеру
    };

    const handleScan = (data) => {
        if (data) {
            onScan(data);
        }
    };

    const handleError = (err) => {
        if (onError) {
            onError(err);
        }
        console.error(err);
    };

    return (
        <div style={{textAlign: "center"}}>
            <QrScanner
                delay={300}
                style={previewStyle}
                constraints={{video: videoConstraints}}
                onError={handleError}
                onScan={handleScan}
            />
        </div>
    );
};

export default QrScannerComponent;
