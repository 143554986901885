import React, { useEffect, useState } from 'react';
import styles from './MyStations.module.css';
import { checkToken } from "../../Utils/TokenService";
import { useNavigate } from "react-router-dom";
import config from '../../../settings/config';
import { toast } from "react-toastify";
import plusIcon from "../../../img/plus-station.svg"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ministation from "../../../img/station-mini.svg"

const MyStations = () => {
    const [stations, setStations] = useState([]);
    const token = localStorage.getItem('m_token')
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    const navigate = useNavigate();

    // проверим токен для мобильного бэка
    useEffect(() => {
        if (!checkToken(token)) {
            toast.error('Сессия истекла, авторизируйтесь снова:', {
                position: "top-center",
                autoClose: 3000
            });
            navigate('/step2');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            // Проверяем токенг скунса перед каждым запросом, если истекло время токена, рефрешим его
            try {
                const response = await fetch(`${config.app.host}/api/v1/station/list`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    toast.error('Ошибка получения данных ' + response.status, {
                        position: "top-center",
                        autoClose: 3000
                    });
                }
                const result = await response.json();
                console.log(result)
                setStations(result);
            } catch (error) {
                toast.error('Ошибка ' + error, {
                    position: "top-center",
                    autoClose: 3000
                });
            }
        };
        fetchData();
        // const intervalId = setInterval(fetchData, 30000);
        // return () => clearInterval(intervalId);
    }, []);

    const handleConnect = () => {
        navigate('/connect');
    }

    const handleCardClick = (station) => {
        console.log(station)
        navigate('/stationControl', { state: { station } });
    };

    console.log(stations)

    return (
        <div className={styles.container}>
            <div className={styles.sectionHeader}></div>
            <div className={styles.section}>
                <h1 className={styles.titleText}>Мои станции</h1>
            </div>
            <div className={stations?.length === 0 ? styles.section : styles.hidden}>
                <p className={styles.mainText}>У вас пока нет добавленных станций, для подключения вам понадобится физический доступ к зарядной станции</p>
            </div>
            <div className={stations?.length >= 0 ? styles.mainSection : styles.hidden}>
                {stations?.length > 1 ? (
                    <Slider {...sliderSettings}>
                        {stations?.map((station, index) => (
                            <div
                                key={index}
                                className={styles.card}
                                onClick={() => handleCardClick(station)}
                            >
                                <div className={styles.information}>
                                    <div className={styles.status}>
                                        <p className={styles.paramText}>{station.connectors[0].status}</p>
                                    </div>
                                    <div className={styles.parameters}>
                                        <div className={styles.paramContainer}><p className={styles.paramText}>Type2</p></div>
                                        <div className={styles.paramContainer}><p className={styles.paramText}>{station.powerCp}кВт</p></div>
                                    </div>
                                </div>
                                <div className={styles.imageContainer}>
                                    <img src={ministation} className={styles.miniStation} alt='ministation' />
                                </div>
                                <div className={styles.nickName}>
                                    <p className={styles.textStationName}>{station.nickName}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div
                        className={styles.card}
                        onClick={() => handleCardClick(stations[0])}
                    >
                        <div className={styles.information}>
                            <div className={styles.status}>
                                <p className={styles.paramText}>{stations[0]?.connectors[0].status}</p>
                            </div>
                            <div className={styles.parameters}>
                                <div className={styles.paramContainer}><p className={styles.paramText}>Type2</p></div>
                                <div className={styles.paramContainer}><p className={styles.paramText}>{stations[0]?.powerCp}кВт</p></div>
                            </div>
                        </div>
                        <div className={styles.imageContainer}>
                            <img src={ministation} className={styles.miniStation} alt='ministation' />
                        </div>
                        <div className={styles.nickName}>
                            <p className={styles.textStationName}>{stations[0]?.nickName}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.section}>
                <div className={styles.skipContainer}>
                    <button
                        type="button"
                        onClick={handleConnect}
                        className={styles.submitButtonConnect}
                    >
                        <p className={styles.textButtons}>Добавить станцию</p><img src={plusIcon} className={styles.plusIcon} alt='plus' />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MyStations;