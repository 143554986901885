import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PinCodeScreen.module.css'
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { DeviceUUID } from "device-uuid";
import config from "../../../settings/config";
import { checkToken } from "../../Utils/TokenService";

const PinCodeScreen = () => {

    let [otp, setOtp] = useState('');
    const navigate = useNavigate();

    function handleOtpChange(otp) {
        if (otp.length >= 4) {
            console.log(otp)
            handlePinComplite(otp);
        }
        setOtp(otp);
    }

    function handleOtpClear() {
        setOtp('')
    }

    useEffect(() => {
        const m_token = localStorage.getItem('m_token')
        if (!checkToken(m_token)) {
            navigate('/step2');
        }
    }, []);


    function handlePinComplite(values) {
        const storagePin = localStorage.getItem('pin');
        if (values === storagePin) {
            async function tokenUpdateHandle() {
                let uuid = new DeviceUUID().get();
                const authRequest = {
                    username: localStorage.getItem('phone').replace(/\D/g, ''),
                    password: null,
                    deviceId: uuid
                }
                try {
                    const response = await fetch(`${config.app.host}/api/v1/protected/updtoken`, {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('m_token')}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(authRequest),
                    });

                    if (!response.ok) {
                        toast.error('Error ', {
                            position: "top-center",
                            autoClose: 3000
                        });
                    }

                    const token = await response.json();
                    localStorage.setItem('s_token', token.skunsToken);
                    /*toast.success('C - tокен успешно обновлён', {
                        position: "top-center",
                        autoClose: 3000
                    });*/

                } catch (error) {
                    toast.error('Ошибка:' + error.message, {
                        position: "top-center-center",
                        autoClose: 3000
                    });
                }

            }

            tokenUpdateHandle()
            navigate('/myStations');
        } else {
            toast.error('Неверный PIN-код', {
                position: "top-center",
                autoClose: 3000
            });
            otp = handleOtpClear();
            navigate('/step2');
            return;
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
            <div className={styles.titleText}>Введите пароль:</div>
            </div>
            <div className={styles.section}>
                <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span></span>}
                    renderInput={(props, index) => <input
                        {...props}
                        className={styles.otpInput}
                        type="number"
                        pattern="\d*"
                        inputmode="decimal"
                        autoFocus={index === 0} 
                    />}
                    containerStyle={styles.otpInlineInput}
                />
            </div>
            <div className={styles.section}>
                <button className={styles.button}
                    onClick={() => {
                        localStorage.removeItem('pin');
                        navigate('/step2');
                    }}
                >Сбросить pin-код
                </button>
            </div>
        </div>
    );
}

export default PinCodeScreen;